.content {
  position: relative;
  font-family: 'Rubik', sans-serif;
  color: #1a3065;
  overflow: hidden;
}
.box {
  position: relative;
  font-family: 'Rubik', sans-serif;
  line-height: 26px;
  color:#1a3065;
}
.center{
  text-align: center;
  position: relative;
}
.content__container {
  height: 40px;
}
.highlight {
  line-height: 52px;
  background-color: #facc57;
}
.content__container__list {
  white-space: nowrap;
  color:#facc57;
  text-align: left;
  list-style: none;
  -webkit-animation-name: change;
  -webkit-animation-duration: 15s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 15s;
  animation-iteration-count: infinite;
}
@keyframes change {
  0%, 7%, 100% {transform:translate3d(0,0,0);}
  10%,17% {transform:translate3d(0,-10%,0);}
  20%,27% {transform:translate3d(0,-20%,0);}
  30%,37% {transform:translate3d(0,-30%,0);}
  40%,47% {transform:translate3d(0,-40%,0);}
  50%,57% {transform:translate3d(0,-50%,0);}
  60%,67% {transform:translate3d(0,-60%,0);}
  70%,77% {transform:translate3d(0,-70%,0);}
  80%,87% {transform:translate3d(0,-80%,0);}
  90%,97% {transform:translate3d(0,-90%,0);}
  100%,107% {transform:translate3d(0,-100%,0);}
}
.hideMenuNav {
  display: none;
}
.grecaptcha-badge {
}
