.rating {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-direction: row-reverse;
  position: relative;
}
.rating > input {
  display: none;
}
.rating > label {
  cursor: pointer;
  width: auto; 
  margin: 5px;
  height: 82px;
  margin-top: auto;
  background-repeat: no-repeat;
  transition: 0.3s;
  font-size: 70px;
}
.rating input:checked+label {
  cursor: pointer;
  width: auto; 
  margin: 5px;
  height: 82px;
  margin-top: auto;
  background-repeat: no-repeat;
  transition: 0.3s;
  font-size: 70px;
  color: #6D97F2;
}
.emoji-wrapper {
  width: 100%;
  height: 100px;
  overflow: hidden;
}
.emoji-wrapper:before, .emoji-wrapper:after {
  height: 15px;
  width: 100%;
  
  z-index: 1;
}
.emoji {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
}
.emoji > svg {
  margin: 15px 0;
  width: 70px;
  height: 70px;
}
.r-1:checked ~ .emoji-wrapper > .emoji {
  transform: translateY(-100px);
}
.r-2:checked ~ .emoji-wrapper > .emoji {
  transform: translateY(-200px);
}
.r-3:checked ~ .emoji-wrapper > .emoji {
  transform: translateY(-300px);
}
.r-4:checked ~ .emoji-wrapper > .emoji {
  transform: translateY(-400px);
}
.r-5:checked ~ .emoji-wrapper > .emoji {
  transform: translateY(-500px);
}