#questions label, #questions label {
    padding: 10px;
    flex-grow: 1;
    color:#1a3065;
    font-size: 30px;
    border-radius: 10px;
    background-color: white;
    -webkit-user-select:none;
    -moz-user-select:none;
    user-select:none;
    text-align: center;
}

#questions input:checked:before, #questions input:checked+label:before {
    font-family: fontawesome;
    content: "\f00c";
    color: #6E98F2;
    margin-right: 20px;
    margin-left: 20px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size:35px;
    background-color: white;
    border-radius: 999px;
}

#questions input:not(:checked):before, #questions input:not(:checked)+label:before {
    font-family: fontawesome;
    content: "\f057";
    margin-right: 20px;
    margin-left: 20px;
    padding-right: 7px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size:35px;
    color:#1a3065;
}

#questions input:checked+label, #questions input:checked+label {
    color: white;
    background-color: #6E98F2;
}